@import "src/themes/daikiri/styles/index.scss";
.ThemePicker {
  &__wrapper {
    display: flex;
    gap: space(0.5);
    width: 100%;

    @include md {
      gap: space(1.5);
    }
  }

  &__custom {
    align-items: center;

    button {
      margin-top: 0 !important;
    }
  }

  &__item {
    border: 1px solid getvar($colors, "neutral", "500");
    border-radius: space(0.25);
    cursor: pointer;
    // padding: space(0.5) space(0.25);
    padding: space(0.5);
    width: 10%;

    @include sm {
      width: 160px;
      padding: space() space(0.5);
    }

    @include lg {
      width: 15%;
      padding: space() space(0.75);
    }

    span {
      display: block;
    }

    &--center {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &__labels {
    border: 1px solid transparent;
    padding: space(0.75) 0 0 0;
    flex-basis: content;
    display: flex;
    flex-wrap: wrap;
    margin-right: space(1.5);
    flex-direction: column;

    @include lg {
      margin-right: space(0.5);
      padding: space(1.25) 0 0 0;
      width: auto;
    }
  }

  &__label {
    &--first {
      margin-bottom: space(0.5);

      @include lg {
        margin-bottom: space(1.25);
      }
    }
  }

  &__swatch {
    border-radius: space(0.25);
    display: inline-flex;
    width: 100%;
    height: space(1.5);

    @include lg {
      height: space(2);
    }

    &--first {
      margin-bottom: space(0.5);

      @include lg {
        margin-bottom: space(1.5);
      }
    }
  }

  &__add {
    background-color: getvar($colors, "primary", "500");
    color: getvar($colors, "neutral", "50");
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-weight: bold;
    font-size: space();
    border-radius: space(0.25);
    transition: ease-in-out 0.2s background-color;

    @include sm {
      padding: 0 space(0.5);
    }

    @include lg {
      padding: space(0.35) space();
      font-size: space(1.5);
    }

    &:hover {
      background-color: getvar($colors, "primary", "600");
    }

    & div {
      padding-bottom: 4px;
    }
  }
  &__add.button--rounded {
    @extend .u-rounded-button;
  }

  &--active {
    @extend .u-active;
  }
}
